import React, { useReducer, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { Grid, Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import validate from '../../../../../utils/validation';

const paid = [
  {
    value: false,
    label: 'false',
  },
  {
    value: true,
    label: 'true',
  },
];

const FIELDS = {
  text: {
    label: 'Text',
    value: '',
    type: 'text',
    isRequired: true,
  },
  author: {
    label: 'Author',
    value: '',
    type: 'text',
    isRequired: false,
  },
  collection_id: {
    label: 'Collection',
    value: '',
    type: 'select',
    isRequired: true,
    options: [],
  },
  paid: {
    label: 'Paid',
    value: false,
    type: 'select',
    isRequired: false,
    options: paid,
  },
  // short_text: {
  //   label: 'Short Text',
  //   value: '',
  //   type: 'text',
  // },
  // cover_url: {
  //   label: 'Cover URL',
  //   value: '',
  //   type: 'text',
  // },
  // video_url: {
  //   label: 'Video URL',
  //   value: '',
  //   type: 'text',
  // },
  like_count: {
    label: 'Like Count',
    value: '0',
    type: 'number',
    isRequired: false,
  },
  play_amount: {
    label: 'Play Amount',
    value: '0',
    type: 'number',
    isRequired: false,
  },
  share_count: {
    label: 'Share Count',
    value: '0',
    type: 'number',
    isRequired: false,
  },
};

const formReducer = (state, e) => {
  const { name, value } = e;
  const { type } = state[name];

  return {
    ...state,
    [name]: {
      ...e,
      ...FIELDS[name],
      ...state[name],
      value,
      isValid: !FIELDS[name].isRequired ? true : validate(value, type, 1),
      isTouched: e.touch,
    },
  };
};

const initialFields = Object.keys(FIELDS).reduce(
  (acc, key) => ({
    ...acc,
    [key]: {
      ...FIELDS[key],
      value: FIELDS[key]?.value,
      isValid: FIELDS[key]?.value ? validate(FIELDS[key]?.value) : false,
      isTouched: false,
    },
  }),
  {},
);

export default function ModalForm({ isOpen, onClose, onSubmit, selected, collectionList }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isPending = useSelector((state) => state.apps.isPending);
  const activeCollectionId = useSelector((state) => state.apps.inspiro.message.activeCollectionId);

  const initialForm = useMemo(() => {
    if (selected) {
      return Object.keys(FIELDS).reduce((acc, key) => {
        if (!Object.keys(FIELDS).includes(key)) return acc;

        if (key === 'author') {
          return {
            ...acc,
            [key]: {
              ...FIELDS[key],
              name: key,
              value: selected[key].name,
              isValid: selected[key] ? validate(selected[key], FIELDS[key].type, 1) : false,
              isTouched: false,
            },
          };
        }

        if (key === 'collection_id') {
          return {
            ...acc,
            [key]: {
              ...FIELDS[key],
              options: collectionList.map((i) => ({
                value: i.name,
                label: i.name,
              })),
              name: key,
              value: selected[key],
              isValid: selected[key] ? validate(selected[key], FIELDS[key].type, 1) : false,
              isTouched: false,
            },
          };
        }

        return {
          ...acc,
          [key]: {
            ...FIELDS[key],
            name: key,
            value: selected[key],
            isValid: selected[key] ? validate(selected[key], FIELDS[key].type, 1) : false,
            isTouched: false,
          },
        };
      }, {});
    }

    initialFields.collection_id.options = collectionList.map((i) => ({
      value: i.name,
      label: i.name,
    }));

    if (activeCollectionId) {
      const collectionName = collectionList.find((c) => c.id === activeCollectionId)?.name;
      initialFields.collection_id.value = collectionName;
    } else {
      initialFields.collection_id.value = '';
    }

    return initialFields;
  }, [selected, collectionList, activeCollectionId]);

  const [fields, setFormData] = useReducer(formReducer, initialForm);

  const validateForm = () =>
    new Promise((res) => {
      Object.keys(fields).map((key, index) => {
        setFormData({
          name: key,
          value: fields[key].value,
          touch: true,
        });

        if (Object.keys(fields).length - 1 === index) {
          res(true);
        }
        return key;
      });
    });

  const handleSubmit = async (e) => {
    e.preventDefault();
    await validateForm();

    const valuesFields = Object.keys(fields).reduce((acc, key) => {
      if (selected) {
        if (!fields[key].isTouched) {
          return acc;
        }
      }

      if (
        _.isString(fields[key].value) ||
        _.isBoolean(fields[key].value) ||
        _.isNumber(fields[key].value) ||
        _.isArray(fields[key].value)
      ) {
        const value = fields[key].type === 'number' ? Number(fields[key].value) : fields[key].value;
        return {
          ...acc,
          [key]: value,
        };
      }

      return acc;
    }, {});

    const validFields = Object.values(fields).filter((i) => !i.isRequired || (i.isRequired && i.isValid));
    const requiredFields = Object.values(fields).filter((i) => i.isRequired);

    const invalidFields = requiredFields.reduce((acc, field) => {
      const isExists = validFields.find((f) => f.name === field.name);
      if (!isExists) {
        acc.push(field);
      }
      return acc;
    }, []);

    console.log('invalidFields', invalidFields);

    if (!invalidFields.length) {
      onSubmit(valuesFields);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    const { name } = e.target;

    setFormData({
      name,
      value,
      touch: true,
    });
  };

  const renderField = (key, attributes) => {
    const { label, isValid, isTouched, isRequired, value, type, placeholder = '', options } = attributes;

    if (type === 'select') {
      return (
        <TextField
          key={key}
          id={key}
          name={key}
          select
          label={label}
          value={value}
          onChange={handleChange}
          variant="outlined"
          error={isRequired && !isValid && isTouched}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      );
    }

    if (type === 'multiple') {
      const items = options.filter((o) => o !== fields?.collection_name?.value);
      const v = !_.isArray(value) ? [] : value;
      return (
        <FormControl className={classes.formControl} variant="outlined">
          <InputLabel>{label}</InputLabel>
          <Select
            className={classes.field}
            label={key}
            name={key}
            variant="outlined"
            value={v}
            id={key}
            onChange={handleChange}
            autoWidth
            multiple
          >
            <MenuItem value="">&nbsp;</MenuItem>

            {items.map((item) => {
              const val = _.isBoolean(item) ? JSON.stringify(item) : item;
              return (
                <MenuItem key={`${val}_item`} value={val}>
                  {val}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      );
    }

    return (
      <TextField
        key={key}
        placeholder={placeholder}
        name={key}
        id={key}
        label={label}
        value={value}
        error={isRequired && !isValid && isTouched}
        onChange={handleChange}
        variant="outlined"
        type={type}
      />
    );
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.container}>
          {isPending ? (
            <CircularProgress size={26} className={classes.loader} />
          ) : (
            <>
              <div className={classes.header}>
                <h2 className={classes.title}>{!_.isEmpty(selected) ? 'Edit Message' : 'Create Message'}</h2>
              </div>
              <form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
                {Object.keys(fields).map((key) => renderField(key, fields[key]))}

                <Button variant="contained" size="large" color="primary" type="submit" onClick={handleSubmit}>
                  Submit
                </Button>
              </form>
            </>
          )}
        </div>
      </Fade>
    </Modal>
  );
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minWidth: 500,
    minHeight: 400,
    maxHeight: '90%',
    overflowX: 'hidden',
    overflowY: 'scroll',
    position: 'relative',
  },
  loader: {
    position: 'absolute',
    top: 'calc(50% - 12px)',
    left: 'calc(50% - 12px)',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  header: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 20,
    margin: 0,
  },
  headerDeleteIcon: {
    fontSize: 28,
  },
}));
