import { SIGN_OUT } from '../auth/types';

import * as TYPES from './types';

export const initialState = {
  pending: false,
  emails: {
    data: [],
    count: 0,
  },
  addresses: [],
  favoriteAddresses: [],
  templates: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_PENDING:
      return {
        ...state,
        pending: action.payload,
      };

    case TYPES.SET_DATA:
      return {
        ...state,
        emails: {
          ...state.emails,
          data: action.payload.data,
          count: action.payload.count,
        },
      };

    case TYPES.SET_ADDRESSES:
      return {
        ...state,
        addresses: action.payload,
      };

    case TYPES.SET_FAVORITE_ADDRESSES:
      return {
        ...state,
        favoriteAddresses: action.payload,
      };

    case TYPES.SET_TEMPLATES:
      return {
        ...state,
        templates: action.payload,
      };

    case SIGN_OUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
