import React, { useReducer, useEffect, useMemo } from 'react';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import { Delete as DeleteIcon } from '@material-ui/icons';
import validate from '../../utils/validation';

const PLATFORMS_TYPES = {
  ANDROID: 'android',
  IOS: 'ios',
  WEB: 'web',
};

const platforms = [
  {
    value: PLATFORMS_TYPES.ANDROID,
    label: PLATFORMS_TYPES.ANDROID,
  },
  {
    value: PLATFORMS_TYPES.IOS,
    label: PLATFORMS_TYPES.IOS,
  },
  {
    value: PLATFORMS_TYPES.WEB,
    label: PLATFORMS_TYPES.WEB,
  },
];

const FIELDS = {
  name: {
    label: 'Project Name',
    isRequired: true,
  },
  platform: {
    label: 'Platform',
    value: '',
    type: 'select',
    isRequired: true,
  },
};

const FB_FIELDS = {
  pixelId: {
    label: 'FB Pixel Id',
    isRequired: false,
  },
  pixelAccessToken: {
    label: 'FB Pixel Access Token',
    isRequired: false,
  },
  conversionsApiId: {
    label: 'FB Conversions API Id',
    isRequired: false,
  },
  conversionsApiAccessToken: {
    label: 'FB Conversions API Token',
    isRequired: false,
  },
};

const APP_FIELDS = {
  bundleId: {
    label: 'Bundle Id',
    isRequired: false,
  },
  appId: {
    label: 'App Id',
    isRequired: false,
  },
  email: {
    label: 'Email',
    isRequired: false,
  },
};

const ANDROID_FIELDS = {
  ...APP_FIELDS,
  clientEmail: {
    label: 'Client Email',
    value: '',
    isRequired: false,
  },
  privateKey: {
    label: 'Private Key',
    value: '',
    isRequired: false,
  },
  ...FB_FIELDS,
};

const IOS_FIELDS = {
  ...APP_FIELDS,
  // connectApiToken: {
  //   label: 'Connect Api Token',
  //   value: '',
  //   isValid: false,
  //   isRequired: false,
  // },
  billingKey: {
    label: 'Billing Key',
    isRequired: false,
  },
  ...FB_FIELDS,
};

const ALL_FIELDS = {
  ...FIELDS,
  ...ANDROID_FIELDS,
  ...IOS_FIELDS,
};

const PLATFORMS_FIELDS = {
  [PLATFORMS_TYPES.ANDROID]: ANDROID_FIELDS,
  [PLATFORMS_TYPES.IOS]: IOS_FIELDS,
  [PLATFORMS_TYPES.WEB]: FIELDS,
};

const formReducer = (state, e) => {
  const { value } = e;
  let newState = state;
  if (e.name === 'platform') {
    let excludeFields = null;

    if (value === PLATFORMS_TYPES.ANDROID) {
      excludeFields = IOS_FIELDS;
    } else if (value === PLATFORMS_TYPES.IOS) {
      excludeFields = ANDROID_FIELDS;
    } else if (value === PLATFORMS_TYPES.WEB) {
      excludeFields = { ...IOS_FIELDS, ...ANDROID_FIELDS };
    }

    newState = Object.keys(state).reduce((acc, key) => {
      if (excludeFields && Object.keys(excludeFields).includes(key)) {
        return acc;
      }

      return {
        ...acc,
        [key]: {
          ...state[key],
        },
      };
    }, {});
  }

  return {
    ...newState,
    [e.name]: {
      ...e,
      ...newState[e.name],
      value,
      isValid: validate(value, e.name, 2),
      isTouched: e.touch,
    },
  };
};

const initialFields = Object.keys(FIELDS).reduce(
  (acc, key) => ({
    ...acc,
    [key]: {
      ...FIELDS[key],
      value: FIELDS[key]?.value ? FIELDS[key]?.value : '',
      isValid: FIELDS[key]?.value ? validate(FIELDS[key]?.value) : false,
      isTouched: false,
    },
  }),
  {},
);

export default function FormDialog({ isOpen, onClose, onSubmit, isPending, isEdit, selectedProject }) {
  const classes = useStyles();

  const projectExistsFields = useMemo(() => {
    if (isEdit && !_.isEmpty(selectedProject)) {
      return {
        ...PLATFORMS_FIELDS[selectedProject.platform],
        ...selectedProject,
      };
    }
    return {};
  }, [isEdit, selectedProject]);

  const initialForm = useMemo(() => {
    if (!_.isEmpty(projectExistsFields)) {
      return Object.keys(PLATFORMS_FIELDS[selectedProject.platform]).reduce((acc, key) => {
        if (key === '_id' || key === 'token') return acc;

        return {
          ...acc,
          [key]: {
            ...ALL_FIELDS[key],
            name: key,
            value: _.isObject(projectExistsFields[key]) ? '' : projectExistsFields[key],
            isValid: projectExistsFields[key] ? validate(projectExistsFields[key]) : false,
            isTouched: false,
          },
        };
      }, {});
    }

    return initialFields;
  }, [projectExistsFields]);

  const [fields, setFormData] = useReducer(formReducer, initialForm);

  const validateForm = () => {
    Object.keys(fields).map((key) => {
      setFormData({
        ...fields[key],
        name: key,
        value: fields[key].value,
      });
      return key;
    });
  };

  const handleDeleteProject = () => {
    console.log('delete', selectedProject);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    const { name } = e.target;

    setFormData({
      name,
      value,
      touch: true,
    });

    if (name === 'platform') {
      if (value === PLATFORMS_TYPES.ANDROID) {
        Object.keys(ANDROID_FIELDS).map((key) => {
          setFormData({
            ...ANDROID_FIELDS[key],
            name: key,
            label: ANDROID_FIELDS[key].label,
            value: ANDROID_FIELDS[key].value,
            touch: false,
          });
          return key;
        });
      }

      if (value === PLATFORMS_TYPES.IOS) {
        Object.keys(IOS_FIELDS).map((key) => {
          setFormData({
            ...IOS_FIELDS[key],
            name: key,
            label: IOS_FIELDS[key].label,
            value: IOS_FIELDS[key].value,
            touch: false,
          });
          return key;
        });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    validateForm();

    const valuesFields = Object.keys(fields).reduce((acc, key) => {
      if (!fields[key].isTouched) {
        return acc;
      }

      let { value } = fields[key];

      if (key === 'pixelId' && fields[key].value) {
        value = +fields[key].value;
      }

      if (value) {
        return {
          ...acc,
          [key]: value,
        };
      }

      return acc;
    }, {});

    const validFields = Object.values(fields).filter((i) => !i.isRequired || (i.isRequired && i.isValid));

    if (!_.isEmpty(projectExistsFields) && Object.keys(valuesFields).length) {
      valuesFields.token = projectExistsFields.token;
    }

    if (validFields.length === Object.keys(fields).length) {
      onSubmit(valuesFields);
    }
  };

  const renderField = (key, attributes) => {
    const { label, isValid, isRequired, isTouched, value, type, placeholder = '' } = attributes;
    if (type === 'select') {
      return (
        <TextField key={key} id={key} name={key} select label={label} value={value} onChange={handleChange} variant="outlined">
          {platforms.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      );
    }

    return (
      <TextField
        key={key}
        placeholder={placeholder}
        name={key}
        id={key}
        label={label}
        value={value}
        error={isRequired && !isValid && isTouched}
        onChange={handleChange}
        variant="outlined"
      />
    );
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.container}>
          {isPending ? (
            <CircularProgress size={26} className={classes.loader} />
          ) : (
            <>
              <div className={classes.header}>
                <h2 className={classes.title}>{isEdit ? 'Edit Project' : 'Create New Project'}</h2>

                {!_.isEmpty(projectExistsFields) && projectExistsFields.token && (
                  <IconButton
                    color="inherit"
                    aria-haspopup="true"
                    aria-controls="profile-menu"
                    onClick={handleDeleteProject}
                    className={classes.headerDeleteButton}
                  >
                    <DeleteIcon classes={{ root: classes.headerDeleteIcon }} />
                  </IconButton>
                )}
              </div>
              {!_.isEmpty(projectExistsFields) && projectExistsFields.token && (
                <div className={classes.headerTokenWrap}>
                  <p className={classes.headerTokenTitle}>Token</p>
                  <p className={classes.headerToken}>{projectExistsFields.token}</p>
                </div>
              )}
              <form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
                {Object.keys(fields).map((key) => renderField(key, fields[key]))}

                <Button variant="contained" size="large" color="primary" type="submit" onClick={handleSubmit}>
                  Submit
                </Button>
              </form>
            </>
          )}
        </div>
      </Fade>
    </Modal>
  );
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '90%',
    maxWidth: 500,
    maxHeight: '90%',
    overflow: 'scroll',
    position: 'relative',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  header: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerTokenWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  headerToken: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: 'rgba(0,0,0,0.1)',
    fontSize: 10,
  },
  headerTokenTitle: {
    fontSize: 15,
  },
  title: {
    fontSize: 20,
    margin: 0,
  },
  headerDeleteIcon: {
    fontSize: 28,
  },
}));
