import { createAction } from 'redux-actions';
import { v4 as uuid } from 'uuid';
import * as TYPES from './types';
import * as emailingApi from '../../api/emailing';

export const setPending = createAction(TYPES.SET_PENDING);
export const setData = createAction(TYPES.SET_DATA);
export const setAddresses = createAction(TYPES.SET_ADDRESSES);
export const setFavoriteAddresses = createAction(TYPES.SET_FAVORITE_ADDRESSES);
export const setTemplates = createAction(TYPES.SET_TEMPLATES);

export const getEmails = () => async (dispatch) => {
  dispatch(setPending(true));
  try {
    const data = await emailingApi.getEmails();
    dispatch(setData(data));
    return data;
  } catch (error) {
    console.log('[GET EMAILS ERROR]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const sendEmail = (data) => async (dispatch) => {
  dispatch(setPending(true));
  try {
    const response = await emailingApi.sendEmail(data);
    console.log('SENT RES: ', response);
    return response;
  } catch (error) {
    console.log('[SEND EMAIL ERROR]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const addAddresses = (address) => (dispatch, getState) => {
  const {
    emailing: { addresses },
  } = getState();

  dispatch(setAddresses([...addresses, address]));
};

export const addAddressToFavorite = (address) => (dispatch, getState) => {
  const {
    emailing: { addresses, favoriteAddresses },
  } = getState();

  const filteredAddresses = addresses.filter((i) => !address.includes(i));
  const filteredFavorite = favoriteAddresses.filter((i) => !address.includes(i));
  dispatch(setFavoriteAddresses([...filteredFavorite, ...addresses]));
  dispatch(setAddresses(filteredAddresses));
};

export const removeFavoriteAddress = (address) => (dispatch, getState) => {
  const {
    emailing: { favoriteAddresses },
  } = getState();

  const filtered = favoriteAddresses.filter((i) => i !== address);

  dispatch(setFavoriteAddresses(filtered));
};

export const removeAddress = (address) => (dispatch, getState) => {
  const {
    emailing: { addresses },
  } = getState();

  const filtered = addresses.filter((i) => i !== address);
  dispatch(setAddresses(filtered));
};

export const addTemplate = (template) => (dispatch, getState) => {
  const {
    emailing: { templates },
  } = getState();

  dispatch(
    setTemplates([
      ...templates,
      {
        ...template,
        id: uuid(),
      },
    ]),
  );
};

export const removeTemplate = (id) => (dispatch, getState) => {
  const {
    emailing: { templates },
  } = getState();

  const filteredTemplates = templates.filter((t) => t.id !== id);
  dispatch(setTemplates(filteredTemplates));
};
