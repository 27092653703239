import React, { useReducer, useEffect, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import { Delete as DeleteIcon } from '@material-ui/icons';
import validate from '../../../../utils/validation';

const periods = [
  {
    value: 'P1W',
    label: 'P1W',
  },
  {
    value: 'P1M',
    label: 'P1M',
  },
  {
    value: 'P1Y',
    label: 'P1Y',
  },
];

const currencyCode = [
  {
    value: 'USD',
    label: 'USD',
  },
  {
    value: 'EUR',
    label: 'EUR',
  },
];

const trials = [
  {
    value: false,
    label: 'false',
  },
  {
    value: true,
    label: 'true',
  },
];

const FIELDS = {
  code: {
    label: 'Code',
  },
  currencyCode: {
    label: 'Currency',
    value: 'USD',
    type: 'select',
    options: currencyCode,
  },
  period: {
    label: 'Period',
    value: '',
    type: 'select',
    options: periods,
  },
  trial: {
    label: 'Trial',
    value: false,
    type: 'select',
    options: trials,
  },
};

const formReducer = (state, e) => {
  const { value } = e;
  return {
    ...state,
    [e.name]: {
      ...e,
      ...state[e.name],
      value,
      isValid: validate(value, e.name, 2),
      isTouched: e.touch,
    },
  };
};

const initialFields = Object.keys(FIELDS).reduce(
  (acc, key) => ({
    ...acc,
    [key]: {
      ...FIELDS[key],
      value: FIELDS[key]?.value,
      isValid: FIELDS[key]?.value ? validate(FIELDS[key]?.value) : false,
      isTouched: false,
    },
  }),
  {},
);

export default function ModalForm({ isOpen, onClose, onSubmit, onRemove, isPending, isEdit, selectedProduct }) {
  const classes = useStyles();

  const initialForm = useMemo(() => {
    if (isEdit && selectedProduct) {
      return Object.keys(selectedProduct).reduce((acc, key) => {
        if (key === '_id' || key === 'createdAt' || key === 'updatedAt') return acc;

        return {
          ...acc,
          [key]: {
            ...FIELDS[key],
            name: key,
            value: selectedProduct[key],
            isValid: selectedProduct[key] ? validate(selectedProduct[key]) : false,
            isTouched: false,
          },
        };
      }, {});
    }

    return initialFields;
  }, [isEdit, selectedProduct]);

  const [fields, setFormData] = useReducer(formReducer, initialForm);

  const validateForm = () => {
    Object.keys(fields).map((key) => {
      setFormData({
        name: key,
        value: fields[key].value,
        touch: true,
      });
      return key;
    });
  };

  const handleDeleteProduct = () => {
    if (typeof onRemove === 'function') {
      onRemove();
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    const { name } = e.target;

    setFormData({
      name,
      value,
      touch: true,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    validateForm();

    const valuesFields = Object.keys(fields).reduce((acc, key) => {
      if (isEdit && selectedProduct) {
        if (!fields[key].isTouched) {
          return acc;
        }
      }

      return {
        ...acc,
        [key]: fields[key].value,
      };
    }, {});

    const validFields = Object.values(fields).filter((i) => i.isValid);

    if (validFields.length === Object.keys(fields).length) {
      onSubmit(valuesFields);
    }
  };

  const renderField = (key, attributes) => {
    const { label, isValid, isTouched, value, type, placeholder = '', options } = attributes;

    if (type === 'select') {
      return (
        <TextField key={key} id={key} name={key} select label={label} value={value} onChange={handleChange} variant="outlined">
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      );
    }

    return (
      <TextField
        key={key}
        placeholder={placeholder}
        name={key}
        id={key}
        label={label}
        value={value}
        error={!isValid && isTouched}
        onChange={handleChange}
        variant="outlined"
      />
    );
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.container}>
          {isPending ? (
            <CircularProgress size={26} className={classes.loader} />
          ) : (
            <>
              <div className={classes.header}>
                <h2 className={classes.title}>{isEdit ? 'Edit Product' : 'Create Product'}</h2>

                {isEdit && selectedProduct && (
                  <IconButton
                    color="inherit"
                    aria-haspopup="true"
                    aria-controls="profile-menu"
                    onClick={handleDeleteProduct}
                    className={classes.headerDeleteButton}
                  >
                    <DeleteIcon classes={{ root: classes.headerDeleteIcon }} />
                  </IconButton>
                )}
              </div>
              <form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
                {Object.keys(fields).map((key) => renderField(key, fields[key]))}

                <Button variant="contained" size="large" color="primary" type="submit" onClick={handleSubmit}>
                  Submit
                </Button>
              </form>
            </>
          )}
        </div>
      </Fade>
    </Modal>
  );
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minWidth: 500,
    minHeight: 400,
    maxHeight: '90%',
    overflow: 'scroll',
    position: 'relative',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  header: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 20,
    margin: 0,
  },
  headerDeleteIcon: {
    fontSize: 28,
  },
}));
