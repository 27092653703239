import React, { useState, useCallback, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import { Add as AddIcon, Delete as RemoveIcon } from '@material-ui/icons';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Typography from '@material-ui/core/Typography';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuItem from '@material-ui/core/MenuItem';

import {
  sendEmail,
  addAddresses,
  addAddressToFavorite,
  removeAddress,
  setAddresses,
  removeFavoriteAddress,
} from '../../../store/emailing/actions';

import Widget from '../../../components/Widget/Widget';
import { ROUTES, NAMES, IDS } from '../../../constants/routes';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { prepareStringToArray } from '../../../utils/array';

const breadcrumsRoutes = [
  {
    route: ROUTES[IDS.EMAILING],
    name: NAMES[IDS.EMAILING],
  },
];

const emailTypes = [
  {
    value: 'text',
    label: 'text',
  },
  {
    value: 'html',
    label: 'html',
  },
];

const Email = () => {
  // const navigate = useNavigate();
  const classes = useStyles();
  const [subject, setSubject] = useState('');
  const [type, setType] = useState(emailTypes[0].value);
  const [bodyValue, setBodyValue] = useState('');
  const [value, setValue] = useState('');
  const [checked, setChecked] = useState([]);
  const [checkedFavorite, setCheckedFavorite] = useState([]);
  const dispatch = useDispatch();
  const isPending = useSelector((state) => state.emailing.pending);
  const favoriteAddresses = useSelector((state) => state.emailing.favoriteAddresses);
  const addresses = useSelector((state) => state.emailing.addresses);
  const templates = useSelector((state) => state.emailing.templates);
  const allChecked = [...checked, ...checkedFavorite];

  const groups = useMemo(
    () =>
      templates.reduce((acc, t) => {
        const grp = t.group ? t.group : 'Unknown';
        return {
          ...acc,
          [grp]: acc?.[grp] ? [...acc[grp], t] : [t],
        };
      }, {}),
    [templates],
  );

  const handleSubjectChange = (e) => {
    e.preventDefault();
    setSubject(e.target.value);
  };

  const handleTypeChange = (e) => {
    e.preventDefault();
    setType(e.target.value);
  };

  const handleBodyValueChange = (e) => {
    e.preventDefault();
    setBodyValue(e.target.value);
  };

  const handleEmailChange = (e) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  const handleTemplateClick = useCallback(
    (template) => {
      setSubject(template.subject);
      setBodyValue(template.text);
    },
    [templates],
  );

  const handleAdd = (e) => {
    e.preventDefault();

    const v = prepareStringToArray(value);
    const filtered = v.filter((i) => !addresses.includes(i));
    filtered.map((i) => {
      dispatch(addAddresses(i));
      return i;
    });

    setChecked(filtered);
    setValue('');
  };

  const handleAddToFavorites = (e) => {
    e.preventDefault();
    dispatch(addAddressToFavorite(checked));
    setChecked([]);
  };

  const handleRemoveAddresses = (e) => {
    e.preventDefault();

    if (checked.length) {
      const filtered = addresses.filter((i) => !checked.includes(i));
      dispatch(setAddresses(filtered));
    } else {
      dispatch(setAddresses([]));
    }

    setChecked([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (subject.length && bodyValue.length && allChecked.length) {
      const message = {
        subject,
      };

      if (type === emailTypes[0].value) {
        message.text = bodyValue;
      } else {
        message.html = bodyValue;
      }

      try {
        await dispatch(
          sendEmail({
            to: allChecked,
            message,
          }),
        );
        setSubject('');
        setBodyValue('');
        alert('Email sent successfully');
      } catch (error) {
        alert('Email didn`t send');
      }
    }
  };

  const renderTemplate = (t) => {
    const handleCheckToggle = (e) => {
      e.preventDefault();
      handleTemplateClick(t);
    };

    return (
      <ListItem key={t.id} divider disablePadding dense className={classes.item}>
        <ListItemButton role={undefined} onClick={handleCheckToggle} dense>
          <div className={classes.itemTextWrap}>
            <Typography noWrap color="textPrimary" variant="subtitle1" className={classes.itemTitle}>
              {t.name}
            </Typography>
            <Typography noWrap color="textSecondary" variant="subtitle2" className={classes.itemTitle}>
              {t.subject}
            </Typography>
            <Typography noWrap color="textSecondary" variant="caption" className={classes.itemText}>
              {t.text}
            </Typography>
          </div>
        </ListItemButton>
      </ListItem>
    );
  };

  const renderTemplates = () =>
    Object.keys(groups).map((g) => (
      <Accordion className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography className={classes.heading}>{g}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item xs={12}>
            <List className={classes.list}>{groups[g].map(renderTemplate)}</List>
          </Grid>
        </AccordionDetails>
      </Accordion>
    ));

  const renderItem = (i, index, isFavorite) => {
    const labelId = `checkbox-list-label-${i}`;
    const checkedAddresses = isFavorite ? checkedFavorite : checked;

    const handleRemove = (e) => {
      e.preventDefault();

      if (isFavorite) {
        dispatch(removeFavoriteAddress(i));
      } else {
        dispatch(removeAddress(i));
      }
    };

    const handleCheckToggle = (e) => {
      e.preventDefault();
      const currentIndex = checkedAddresses.indexOf(i);
      const newChecked = [...checkedAddresses];

      if (currentIndex === -1) {
        newChecked.push(i);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      if (isFavorite) {
        setCheckedFavorite(newChecked);
      } else {
        setChecked(newChecked);
      }
    };

    return (
      <ListItem key={`${i}-${index}`} disablePadding dense className={classes.item}>
        <ListItemButton role={undefined} onClick={handleCheckToggle} dense>
          <ListItemIcon className={classes.itemIcon}>
            <Checkbox
              edge="start"
              checked={checkedAddresses.indexOf(i) !== -1}
              tabIndex={-1}
              disableRipple
              inputProps={{ 'aria-labelledby': labelId }}
            />
          </ListItemIcon>
          <div className={classes.itemTextWrap}>
            <Typography noWrap className={classes.itemText}>
              {i}
            </Typography>
          </div>
        </ListItemButton>

        <ListItemSecondaryAction className={classes.rightButton}>
          <IconButton
            // color="secondary"
            edge="start"
            aria-label="remove"
            onClick={handleRemove}
            className={classes.removeButton}
          >
            <RemoveIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  return (
    <>
      <Grid container xs={12}>
        <Grid item xs={10}>
          <Breadcrumbs links={breadcrumsRoutes} currentRoute={NAMES[IDS.EMAIL]} />
        </Grid>
      </Grid>
      <Grid container spacing={4} className={classes.main}>
        <Grid item xs={12}>
          <Widget disableWidgetMenu bodyClass={classes.tableTopOverflow}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <TextField
                    className={classNames(classes.field, classes.fieldTitle)}
                    name="subject"
                    id="subject"
                    label="Subject"
                    value={subject}
                    error={false}
                    onChange={handleSubjectChange}
                    variant="outlined"
                  />
                  <TextField
                    className={classNames(classes.field, classes.fieldBody)}
                    id="type"
                    name="type"
                    select
                    value={type}
                    onChange={handleTypeChange}
                    variant="outlined"
                  >
                    {emailTypes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    className={classNames(classes.field, classes.fieldBody)}
                    name="text"
                    id="text"
                    label="Message Body"
                    value={bodyValue}
                    error={false}
                    onChange={handleBodyValueChange}
                    variant="outlined"
                  />

                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    type="submit"
                    disabled={!allChecked.length || !subject.trim().length || !bodyValue.trim().length}
                    className={classes.button}
                    onClick={handleSubmit}
                  >
                    Send to {allChecked.length} Users
                  </Button>
                </form>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.filedWrap}>
                  <TextField
                    className={classNames(classes.field, classes.fieldAddress)}
                    name="email"
                    id="email"
                    type="email"
                    label="Recipient"
                    value={value}
                    error={false}
                    onChange={handleEmailChange}
                    variant="outlined"
                  />
                  <Fab
                    color="primary"
                    aria-label="add"
                    size="small"
                    onClick={handleAdd}
                    className={classes.addButton}
                    disabled={!value.length}
                  >
                    <AddIcon />
                  </Fab>
                </div>

                {favoriteAddresses.length > 0 && (
                  <Accordion className={classes.accordion}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                      <Typography className={classes.heading}>Favorite</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid item xs={12}>
                        <List className={classes.list}>{favoriteAddresses.map((i, index) => renderItem(i, index, true))}</List>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                )}

                <List className={classes.list}>{addresses.map((i, index) => renderItem(i, index))}</List>

                <Grid container xs={12} justifyContent="space-between" spacing={2}>
                  <Grid item xs={6}>
                    {checked.length > 0 && (
                      <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        type="submit"
                        className={classes.button}
                        onClick={handleAddToFavorites}
                      >
                        Add to Favorites
                      </Button>
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    {addresses.length > 0 && (
                      <Button
                        variant="contained"
                        size="large"
                        color="secondary"
                        type="submit"
                        className={classes.button}
                        onClick={handleRemoveAddresses}
                      >
                        {checked.length > 0 ? `Remove Selected (${checked.length})` : 'Remove All'}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {isPending && (
                <div className={classes.loaderWrap}>
                  <div className={classes.loader}>
                    <CircularProgress size={26} />
                  </div>
                </div>
              )}
            </Grid>
          </Widget>
        </Grid>
      </Grid>

      {Object.keys(groups)?.length > 0 && (
        <Grid container spacing={4} className={classes.main}>
          <Grid item xs={12}>
            <Widget title="Templates" disableWidgetMenu bodyClass={classes.tableTopOverflow}>
              <Grid item xs={12}>
                {renderTemplates()}
              </Grid>
            </Widget>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Email;

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    position: 'relative',
  },
  root: {
    width: '100%',
  },
  main: {
    marginTop: theme.spacing(2),
  },
  tableTopOverflow: {
    display: 'flex',
    flexDirection: 'row',
  },
  filtersLoaderWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    marginTop: theme.spacing(2),
    minHeight: theme.spacing(4),
  },
  filters: {},
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  field: {
    width: '100%',
  },
  fieldBody: {
    marginTop: theme.spacing(2),
  },
  filedWrap: {
    position: 'relative',
  },
  button: {
    width: '100%',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  addButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    transform: 'translate(0, -50%)',
  },
  addresses: {
    display: 'flex',
    width: '100%',
    marginTop: theme.spacing(2),
  },
  list: {
    width: '100%',
    overflow: 'hidden',
  },
  item: {
    width: '100%',
    padding: 0,
  },
  itemIcon: {
    minWidth: '0 !important',
  },
  itemTempalateTextWrap: {
    display: 'flex',
  },
  itemTextWrap: {
    maxWidth: '100%',
  },
  itemText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  rightButton: {
    right: '0 !important',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,1) !important',
  },
  removeButton: {},
  loaderWrap: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.7)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9,
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));
