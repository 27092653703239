import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import DateFnsUtils from '@date-io/moment';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import { createAd, updateAd } from '../../../../store/marketing/actions';

import { formatDate } from '../../../../utils/date';

const MEDIA_SOURCE = {
  FACEBOOK: 'FACEBOOK',
  TIKTOK_ADS: 'TIKTOK_ADS',
  APPLE_SEARCH_ADS: 'APPLE_SEARCH_ADS',
  GOOGLE_ADS: 'GOOGLE_ADS',
};

const TODAY = formatDate();

export default function ModalForm({ isOpen, onClose, onSubmit, selectedAd }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isPending = useSelector((state) => state.marketing.ads.pending);
  const [value, setValue] = useState('');
  const [mediaSourceValue, setMediaSourceValue] = useState('');
  const [startDateValue, setStartDateValue] = useState(TODAY);
  const [isInvalid, setInvalid] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(selectedAd)) {
      if (selectedAd.startedAt) {
        setStartDateValue(moment(selectedAd.startedAt).format('YYYY-MM-DD'));
      }
      if (selectedAd.name) {
        setValue(selectedAd.name);
      }
      if (selectedAd.mediaSource) {
        setMediaSourceValue(selectedAd.mediaSource);
      }
    }
  }, [selectedAd]);

  const handleChange = (e) => {
    setInvalid(false);
    e.preventDefault();
    setValue(e.target.value);
  };

  const handleSelectChange = (e) => {
    setInvalid(false);
    e.preventDefault();
    setMediaSourceValue(e.target.value);
  };

  const handleDateChange = (newValue) => {
    const date = formatDate(newValue);
    setStartDateValue(date);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setInvalid(false);

    if (value.length && startDateValue.length && mediaSourceValue.length) {
      try {
        if (_.isEmpty(selectedAd)) {
          await dispatch(createAd({ name: value, mediaSource: mediaSourceValue, startedAt: startDateValue }));
        } else if (selectedAd._id) {
          await dispatch(updateAd({ _id: selectedAd._id, name: value, mediaSource: mediaSourceValue, startedAt: startDateValue }));
        }
        onSubmit();
      } catch (error) {
        console.log('[CREATE AD MODAL ERROR]', error);
      }
    } else {
      setInvalid(true);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.container}>
          {isPending ? (
            <CircularProgress size={26} className={classes.loader} />
          ) : (
            <>
              <div className={classes.header}>
                <h2 className={classes.title}>{!_.isEmpty(selectedAd) ? 'Edit Ad' : 'Create Ad'}</h2>
              </div>
              <form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.datepicker}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="DD/MM/YYYY"
                    margin="none"
                    id="date-picker-inline"
                    label="Start Date"
                    value={moment(startDateValue, 'YYYY-MM-DD').toDate()}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    className={classes.datepickerLeft}
                  />
                </MuiPickersUtilsProvider>

                <TextField
                  placeholder="Enter Ad Name"
                  name="Ad Name"
                  label="Ad Name"
                  error={isInvalid && !value.length}
                  value={value}
                  onChange={handleChange}
                  variant="outlined"
                />

                <FormControl className={classes.formControl} variant="outlined">
                  <InputLabel>Media source</InputLabel>
                  <Select
                    className={classes.field}
                    label="Media source"
                    name="Media source"
                    variant="outlined"
                    error={isInvalid && !mediaSourceValue.length}
                    value={mediaSourceValue}
                    onChange={handleSelectChange}
                    autoWidth
                  >
                    {Object.keys(MEDIA_SOURCE).map((key) => {
                      const val = MEDIA_SOURCE[key];
                      return (
                        <MenuItem key={val} value={val}>
                          {val}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <Button variant="contained" size="large" color="primary" type="submit" onClick={handleSubmit}>
                  Submit
                </Button>
              </form>
            </>
          )}
        </div>
      </Fade>
    </Modal>
  );
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minWidth: 500,
    minHeight: 200,
    position: 'relative',
  },
  loader: {
    position: 'absolute',
    top: 'calc(50% - 12px)',
    left: 'calc(50% - 12px)',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  header: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 20,
    margin: 0,
  },
  headerDeleteIcon: {
    fontSize: 28,
  },
}));
